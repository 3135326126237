import React from 'react';
import Button from './Button';
import Navigation from './Navigation';


const styles = {
    answersblock: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    text: {
        color: 'var(--text-color-light)'
    }
}

export default ({ title, description, answers, clickHandle, steps, questionNum, clickNav, injection }) => {
    return (
        <div id='question'>
               <Navigation names={[...steps]} current={questionNum} click={clickNav} />
               <h2 style={styles.text}>{title}</h2>
               <p style={styles.text}>{description}</p>
               <div style={styles.answersblock}>
               {injection ? injection : answers.map(
                    answer => <Button
                                key={answer.name}
                                name={answer.name}
                                clickHandle={clickHandle}
                                f={answer.f}
                                value={answer.id}
                                buttonStyle='q-button m-1'
                            />
                ) }
               </div>

        </div>
    );
};