import React from 'react';
import Question from './Question';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import Container from '@material-ui/core/Container';



export default ({ quiz, current, clickNav, clickHandle, checkout, injection }) => {
    const { description, headline, answers } = quiz[current];
    const namesArray = quiz.map(question => question.headline);
    const stepsArray = quiz.map(question => question.step);

    return (
        <Fade timeout={1000} in={true}>
            <Container maxWidth="lg">
                <Grid container spacing={0} direction="row" alignItems="stretch">
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                        <Question
                            names={namesArray}
                            steps={stepsArray}
                            questionNum={current}
                            clickNav={clickNav}
                            description={description}
                            title={headline}
                            answers={answers}
                            clickHandle={clickHandle}
                            injection={injection}
                        />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12} style={{ padding: '20px' }}>{checkout}</Grid>
                </Grid>
            </Container>

        </Fade>
    );
}
