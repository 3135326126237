import React from 'react';
import Navigation from './Navigation';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import { texts } from './data';
import Container from '@material-ui/core/Container';

const styles = {
    text: {
        color: 'var(--text-color-light)'
    }
}

export default ({ quiz, clickNav, checkout, people }) => {
    const stepsArray = quiz.map(question => question.step);
    
    return (
        <Fade timeout={2000} in={true}>
            <Container maxWidth="lg">
                <Grid container spacing={0} direction="row" alignItems="stretch">
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div id='question'>

                            <Navigation names={[...stepsArray]} current={quiz.length} click={clickNav} />
                            <h2 style={styles.text}>{texts.sliderHeadline}</h2>
                            <p style={styles.text}>{texts.sliderText}</p>
                            {people}
                        </div>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} style={{ padding: '20px' }}>{checkout}</Grid>
                </Grid>
            </Container>
        </Fade>
    );
}

