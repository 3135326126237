import React from 'react';
import './Calculator.css';
import data, { texts } from './Calculator-Components/data';
import CalculatorQuestion from './Calculator-Components/CalculatorQuestion';
import CalculatorResult from './Calculator-Components/CalculatorResult';
import Results from './Calculator-Components/Results';
import Slider from './Calculator-Components/Slider';
import { costPerEmployee } from './Calculator-Components/data';


class Calculator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            answers: [],
        };
        this.data = data;

        this.clickHandle = this.clickHandle.bind(this);
        this.toQuestion = this.toQuestion.bind(this);
        this.clearQuiz = this.clearQuiz.bind(this);
    }

    clearQuiz() {
        return this.setState({
            answers: []
        });
    }

    changeItem = (arr, index, value) => {
        return [...arr].map(
            (el, key) => {
                if (key === index) {
                    return value;
                }
                return el;
            }
        );
    }

    clickHandle(answerId) {
        const answers = this.state.answers.length === 1 ? [...this.state.answers, answerId, costPerEmployee] : [...this.state.answers, answerId];
        window.localStorage.setItem('userState', answers);
        return this.setState({
            answers: answers,
        });
    }

    setPeople = (e, value) => {
        e.preventDefault();
        if (this.state.answers.length === 2) {
            this.setState({ answers: [...this.state.answers, 10] })
        }
        const newAnswers = this.changeItem(this.state.answers, 2, value * costPerEmployee);
        window.localStorage.setItem('userState', [...this.state.answers, value]);
        return this.setState({ answers: [...newAnswers] });
    }


    getSum(quiz, answers) {
        if (answers.length === 0) {
            return 0;
        }
        const result = answers.reduce((acc, answer, index) => {
            const [sum, prev] = acc;
            if (index !== 2) {
                const func = quiz[index].answers[answer].f;
                return [func(sum, prev), answers[index]];
            }

            return [sum + answers[index], answers[index]];
        }, [0, null]);

        return result[0];
    }

    buildCheckout(quiz, answers) {
        return answers.reduce((result, answer, answerKey) => {
            const diff = this.getSum(quiz, answers.slice(0, answerKey + 1)) - this.getSum(quiz, answers.slice(0, answerKey))
            if (answerKey !== 2) {
                return [...result, {
                    question: quiz[answerKey].headline,
                    answer: quiz[answerKey].answers[answer].name,
                    adds: diff
                }];
            }

            return [...result, {
                question: texts.personsQty,
                answer: `${answers[2] / costPerEmployee} ${texts.personsItem}`,
                adds: diff
            }];

        }, []);
    }

    toQuestion(index) {
        const newAnswers = [...this.state.answers].slice(0, index);
        return this.setState({
            answers: newAnswers
        });

    }

    getResultsArray() {
        return this.buildCheckout(this.data, this.state.answers);
    }

    makeResultsLine = (resultsArraySrc) => () => {
        const result = [...resultsArraySrc].map(answerLine => {
            return `${answerLine.question}: ${answerLine.answer} (+${answerLine.adds.toLocaleString('ru-RU')})`;
          });
        return result.join(', '); 
    }

    makeFrame() {
        const resultsArray = this.buildCheckout(this.data, this.state.answers);
        return this.state.answers.length > this.data.length - 2 ? (

            <CalculatorResult
                quiz={this.data}
                clickNav={this.toQuestion}
                checkout={<Results results={resultsArray} resultCallback={this.makeResultsLine(this.getResultsArray())} />}
                total={this.getSum(this.data, this.state.answers)}
                people={<Slider handleChange={this.setPeople} />}
                clearHandle={this.clearQuiz}

            />
        ) : (
                <CalculatorQuestion
                    quiz={this.data}
                    current={this.state.answers.length}
                    clickNav={this.toQuestion}
                    clickHandle={this.state.answers.length !== 2 ? this.clickHandle : this.setPeople}
                    injection={this.state.answers.length === 2 ? <Slider handleChange={this.setPeople} /> : false}
                    checkout={<Results results={resultsArray} clearHandle={this.clearQuiz} />}
                />
            );
    }


    render() {
        return this.makeFrame();
    }
}

export default Calculator;